window.onload = function chiama2() { carica(); };

function carica() {
  document.getElementById("body").classList.add("caricato");
  document.getElementById("spinner").classList.add("caricato");
}

setTimeout(function chiama() {
  carica();
}, 300);
